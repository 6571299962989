import {gql} from '@apollo/client';

export const cmsHeroBannerFragment = gql`
    fragment IHeroBanner on CmsLoyaltyWebPerksWebHeroBanner {
        _type
        title
        loggedInTitle
        loggedInActiveTitle
        microcopy
        description
        loggedInDescription
        loggedInActiveDescription
        loggedOut {
            primaryAction {
                text
                href
            }
            secondaryAction {
                text
                href
                prefixText
            }
        }
        loggedIn {
            primaryAction {
                text
                href
            }
        }
        loggedInAndActive {
            primaryAction {
                text
                href
            }
            secondaryAction {
                text
                href
                prefixText
            }
        }
        image {
            alt
            webpSrc2x
            webpSrc1x
            jpegSrc2x
            jpegSrc1x
        }
    }
`;

export const cmsSimpleButtonFragment = gql`
    fragment ISimpleButton on CmsSimpleButton {
        buttonText
        href
        type
    }
`;

export const cmsBottomCallToAction = gql`
    fragment  ISBottomCallToAction on CmsLoyaltyWebPerksWebBottomCallToAction {
        _type
        logo {
            src
            alt
            webpSrc2x
            webpSrc1x
            jpegSrc2x
            jpegSrc1x
        }
        content {
            contentRaw
            primaryAction {
                text
                href
            }
            secondaryAction {
                text
                href
            }
        }
    }
`;

export const cmsDisplayFlagsFragment = gql`
    fragment IDisplayFlags on CmsDisplayFlags {
        customerType
        hasFuelSaver
        hasHPlusMembership
        isAuthenticated
    }
`;

export const cmsVerticalCardFragment = gql`
    ${cmsSimpleButtonFragment}
    ${cmsDisplayFlagsFragment}

    fragment IVerticalCard on CmsVerticalCard {
        _type
        _key
        bodyText: bodyTextRaw
        image {
            alt
            asset {
                url
            }
        }
        videoUrl
        buttons {
            ...ISimpleButton
        }
        imageLinkUrl
        bodyTextLinkUrl
        displayFlags {
            ...IDisplayFlags
        }
        backgroundColor {
            hex
        }
    }
`;

export const cmsHorizontalCardFragment = gql`
    ${cmsSimpleButtonFragment}

    fragment IHorizontalCard on CmsHorizontalCard {
        _type
        _key
        bodyText: bodyTextRaw
        image {
            alt
            asset {
                url
            }
        }
        headerText
        videoUrl
        imageSide
        buttons {
            ...ISimpleButton
        }
        imageLinkUrl
        isExtraWide
        bodyTextLinkUrl
        backgroundColor {
            hex
        }
    }
`;

export const cmsComponentFlagsFragment = gql`
    fragment IComponentFlags on CmsComponentFlags {
        isAuthenticated
        hasFuelSaver
        hasHPlusMembership
        showForNationalCustomers
        hideFromNationalCustomers
    }
`;

export const cmsMarketingBannerFragment = gql`
    fragment IBanner on CmsMarketingBanner {
        __typename
        _key
        _type
        name
        webImage {
            alt
            asset {
                url
                metadata {
                    dimensions {
                        height
                        width
                    }
                }
            }
        }
        tabletImage {
            alt
            asset {
                url
                metadata {
                    dimensions {
                        height
                        width
                    }
                }
            }
        }
        mobileImage {
            alt
            asset {
                url
                metadata {
                    dimensions {
                        height
                        width
                    }
                }
            }
        }
        href
        bgColor{
            hex
        }
        secondaryBgColor {
            hex
        }
    }
`;

export const cmsCardCollectionFragment = gql`
    ${cmsVerticalCardFragment}
    ${cmsHorizontalCardFragment}
    ${cmsDisplayFlagsFragment}

    fragment ICardCollection on CmsCardCollection {
        _type
        maxMobileCards
        textAlignment
        textColor {
            hex
        }
        backgroundColor {
            hex
        }
        cards {
            ... IVerticalCard
            ... IHorizontalCard
        }
        displayFlags {
            ...IDisplayFlags
        }
    }
`;

export const cmsDecorationFragment = gql`
    fragment IDecoration on CmsDecoration {
        _type
        decorationName
        decorationBackgroundColor {
            hex
        }
        decorationColorAccent {
            hex
        }
        decorationColorBackground {
            hex
        }
        withoutHorizontalMargin
        height
        width
    }
`;

export const colorFragment = gql`
    fragment IColor on Color {
        hex
    }
`;

export const cmsTextBlockFragment = gql`
    ${colorFragment}
    ${cmsDisplayFlagsFragment}

    fragment ITextBlock on CmsTextBlock {
        _type
        maxColumns
        text: textRaw
        textAlignment
        verticalDivider
        horizontalDivider
        backgroundColor {
            ...IColor
        }
        textColor {
            ...IColor
        }
        displayFlags {
            ...IDisplayFlags
        }
    }
`;

export const cmsImageCollectionFragment = gql`
    ${cmsComponentFlagsFragment}

    fragment IImageCollection on CmsImageCollection {
        _type
        images {
            image {
                alt
                asset {
                    url
                }
            }
        }
        maxImages
        componentFlags {
            ...IComponentFlags
        }
    }
`;

export const cmsCardSwimlaneFragment = gql`
    ${cmsDisplayFlagsFragment}

    fragment ICardSwimlane on CmsCardSwimlane {
        _type
        _key
        seeAllLink
        title
        backgroundColor {
            hex
        }
        textColor {
            hex
        }
        seeAllLinkColor {
            hex
        }
        cardType {
            ... on CmsStoreProductCollection {
                fuelSaverProducts
                _type
                cards(storeId: $storeId) {
                    __typename
                    storeProductId
                    isWeighted
                    price
                    priceMultiple
                    onSale
                    onFuelSaver
                    fuelSaver
                    storeProductDescriptions {
                        description
                        type
                    }
                    product {
                        averageWeight
                        productId
                        name
                        productImages {
                            size
                            isPrimary
                            uri
                            type
                        }
                        size
                        couponProductV4  {
                            upc
                            couponsV4  {
                                couponId
                                brand
                                offerState
                            }
                        }
                        item {
                            unitAverageWeight
                            retailItems (locationIds: $locationIds) {
                                basePrice
                                soldByUnitOfMeasure {
                                    code
                                }
                                ecommerceTagPrice
                                ecommerceTagPriceQuantity
                                memberTagPrice @include(if: $perksPricingEnabled)
                            }
                        }
                    }
                }
            }

            ... on CmsCouponCategory {
                _type
                category
                cards {
                    __typename
                    couponId
                    offerState
                    imageUrl
                    description
                    clipEndDate
                    valueText
                    upcs
                }
            }
        }
        displayFlags {
            ...IDisplayFlags
        }
    }
`;

export const cmsLoyaltyActionButtonFragment = gql`
    fragment ILoyaltyActionButton on CmsLoyaltyActionButton {
      _type
      text
      destination
      buttonAction
    }
`;

export const cmsSegmentDisplaySegmentFragment = gql`
    ${cmsLoyaltyActionButtonFragment}

    fragment ISegmentDisplaySegment on CmsSegmentDisplaySegment {
      _type
      segmentIcon {
        asset {
            url
        }
      }
      segmentHeader
      segmentBody: segmentBodyRaw
      segmentLoggedInActionButton {
        ...ILoyaltyActionButton
      }
      segmentNotLoggedInActionButton {
        ...ILoyaltyActionButton
      }
    }
`;

export const cmsSegmentDisplay = gql`
    ${cmsSegmentDisplaySegmentFragment}

    fragment ISegmentDisplay on CmsSegmentDisplay {
      _type
      _key
      displayKind
      headerText
      segments {
        ...ISegmentDisplaySegment
      }
    }
`;

export const cmsFuelSaverRedemptionPartners = gql`
    ${cmsLoyaltyActionButtonFragment}

    fragment IFuelSaverRedemptionPartners on CmsFuelSaverRedemptionPartners {
      _type
      _id
      partnerName
      partnerType
      partnerLogoUrl
      partnerLocationDisplayAction {
        ...ILoyaltyActionButton
      }
    }
`;

export const cmsFuelSaverLandingRedemptionLocations = gql`
    ${cmsFuelSaverRedemptionPartners}

    fragment IFuelSaverLandingRedemptionLocations on CmsFuelSaverLandingRedemptionLocations {
      _type
      headerText
      bodyText
      imageUrl
      partner {
        ...IFuelSaverRedemptionPartners
      }
      partnerUrlText
      externalPartnersHeaderText
      externalPartners {
        ...IFuelSaverRedemptionPartners
      }
    }
`;

export const cmsSocialMediaBrand = gql`
    fragment ISocialMediaBrand on CmsSocialMediaBrand {
      name
      logo
    }
`;

export const cmsSocialMediaPost = gql`
    ${cmsSocialMediaBrand}

    fragment ISocialMediaPost on CmsSocialMediaPost {
      socialMediaBrand {
        ...ISocialMediaBrand
      }
      user
      content: contentRaw
      imageUrl
      postDate
      postLink
    }
`;

export const cmsSocialMediaDisplay = gql`
    ${cmsSocialMediaPost}

    fragment ISocialMediaDisplay on CmsSocialMediaDisplay {
      _type
      header
      subheader
      posts {
        ...ISocialMediaPost
      }
    }
`;
